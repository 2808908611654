// HeroSection.js
import React from 'react';

const HeroSection = () => {
  return (
    <section id="home" className="hero">
      <div className="hero-content">
        <h1>Charity Reporting</h1>
        <p>Accounting & finance for charities & not-for-profits</p>
        <a href="#about"><button>Get in touch!</button></a>
      </div>
    </section>
  );
};

export default HeroSection;
