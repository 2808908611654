// ServicesSection.js
import React from 'react';

const ServicesSection = () => {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <div className="service-grid">
        <div className="service">
          <h3>Financial Modelling</h3>
          <p>Custom financial models designed to help you plan for the future, make informed decisions, and allocate resources effectively.</p>
        </div>
        <div className="service">
          <h3>Bespoke Reporting</h3>
          <p>Tailored reporting solutions to streamline your organisation's financial data, providing clear insights for informed decision-making and compliance for the Board and Senior Leadship Teams.</p>
        </div>
        <div className="service">
          <h3>Management Accounting</h3>
          <p>Expert management accounting services aimed at optimiszing your financial processes, budgeting, and performance analysis to achieve your organisation's goals.</p>
        </div>
        <div className="service">
          <h3>Statutory Reporting</h3>
          <p>Accurate and compliant financial reporting services to meet statutory obligations and charity SORP.</p>
        </div>
        <div className="service">
          <h3>Systems & Processes Auditing</h3>
          <p>In-depth analysis and evaluation of your organisation's financial systems and processes to identify and implement improvements, enhance efficiency, and mitigate risks.</p>
        </div>
        <div className="service">
          <h3>General Consulting</h3>
          <p>Comprehensive financial consulting services covering a wide range of areas to support your organisation's financial strategies, growth, and sustainability.</p>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
