// App.js
import React from 'react';
import './App.css'; // Import main styles
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
// import AboutSection from './components/AboutSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="app">
      <div className="background-image"></div>
      <Header />
      <HeroSection />
      <ServicesSection />
      {/* <AboutSection /> */}
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
