// Header.js
import React from 'react';
import { FaBars } from 'react-icons/fa';

const Header = () => {
  return (
    <header className="header">
      <nav>
        <ul>
          <li><a href="#home" className="headerlogo">C</a></li>
          <li><a href="#home">Home</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#menu" className="menu-icon"><FaBars /></a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
